.hero {
  // background-color: #ffe7c3;
  // background-image: url(../../../images/hero-background.jpg);
  .section-inner {
    padding-top: $hero--padding-t__mobile;
    padding-bottom: $hero--padding-b__mobile;
  }
}

.hero-title-outer {
  display: grid;
  //   place-items: center;
  grid-template-areas: "inners";
}

.hero-title-inner {
  grid-area: inners;
}

.visibility-hidden {
  visibility: hidden;
}

.hero-inner {
  // Affects only hero full
  > .hero-content + .hero-figure,
  > .hero-figure + .hero-content {
    margin-top: $hero--inner-padding-v_mobile;
  }

  > .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

@include media("<=medium") {
  .hero {
    // background-position: left bottom;
  background-color: #fcf0df;

    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }
}

@include media(">medium") {
  .hero {
    background-position: center bottom;
    background-image: url(../../../images/undraw_teacher_35j2.svg);
    background-size: cover;
    background-repeat: no-repeat;

    .section-inner {
      padding-top: $hero--padding-t__desktop;
      padding-bottom: $hero--padding-b__desktop;
    }
  }

  .hero-inner {
    padding-top: 96px !important;
    padding-bottom: 96px !important;

    // Affects only hero full
    > .hero-content + .hero-figure,
    > .hero-figure + .hero-content {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }
}
